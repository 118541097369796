import card from '../../assets/images/projects/realtime-parking-gent/card.png';
import banner from '../../assets/images/projects/realtime-parking-gent/banner.png';
import showcaseWide1 from '../../assets/images/projects/realtime-parking-gent/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/realtime-parking-gent/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/realtime-parking-gent/showcase-wide-3.png';
import devider from '../../assets/images/projects/realtime-parking-gent/devider.png';
import mobile1 from '../../assets/images/projects/realtime-parking-gent/mobile-1.png';
import mobile2 from '../../assets/images/projects/realtime-parking-gent/mobile-2.png';
import mobile3 from '../../assets/images/projects/realtime-parking-gent/mobile-3.png';

export default {
    title: "Realtime Parking Gent",
    slug: 'realtime-parking-gent',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/realtime-parking-gent",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: "https://meesakveld.github.io/realtime-parking-gent/",
    },
    description: [
        `This project aims to provide real-time information about parking availability in the city of Gent.`,
        `The website fetches data from an API and displays it, allowing users to see current parking availability in real-time. This project represents my first experience with API data fetching and integration.`
    ],
    developmentNotes: [
        { title: "API Integration", text: "Implemented data fetching from an API to provide real-time parking availability information." },
        { title: "Real-Time Data", text: "Displayed current parking data on the website, enabling users to access up-to-date parking information." },
        { title: "First API Project", text: "This project marks my initial foray into working with APIs, enhancing my skills in data integration and real-time data display." }
    ],
    mostProudOf: [
        `I’m proud of successfully integrating API data fetching into the project. It was a learning experience that allowed me to display real-time parking information effectively on the website.`,
        `I’m also proud of the project’s recognizable and user-friendly design, which makes it easy for users to access the information they need.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}