import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import BgSection from '../components/BgSection'
import Intro from '../components/Intro'
import Title from '../components/Title'
import WorkTogether from '../components/WorkTogether'
import ExpertiseList from '../components/ExpertiseList'

// Assets
import brain from '../assets/images/brain.svg'

// Data
import { development, technologies, visuals } from '../data/expertise'

export default function Expertise() {
    return (
        <>
            <Helmet>
                <title>Expertise | Mees Akveld</title>
                <meta name="twitter:title" content="Expertise | Mees Akveld" />
				<meta property="og:title" content="Expertise | Mees Akveld" />
            </Helmet>

            <div className='flex flex-col gap-0'>
                <BgSection className="pt-24 md:pt-24 flex flex-col gap-24 md:gap-40 z-10">

                    <Intro subtitle="Always getting better:" titlePrt1="Living" titlePrt2="And learning" />

                    <Title title='Expertise' titlePrt1='Exper' titlePrt2='Tise' titleH='h1' imgPath={brain} imgClassName="h-[50vw]" />

                    <div className='flex flex-col gap-20'>

                        {/* Development */}
                        <ExpertiseList
                            title='Development'
                            description='As a developer, I create websites and apps for both mobile devices and desktops. My projects range from simple web applications to complex platforms that process large amounts of data. I aim to deliver innovative and user-friendly solutions.'
                            index='1'
                            expertises={development}
                        />

                        {/* Technologies */}
                        <ExpertiseList
                            title='Technologies'
                            description='I use a variety of technologies to build websites and apps. These tools help me create dynamic and interactive user interfaces that are optimized for performance and accessibility.'
                            index='2'
                            expertises={technologies}
                        />

                        {/* Visuals */}
                        <ExpertiseList
                            title='Visuals'
                            description='I use these tools to create visual designs that are engaging and accessible. My goal is to create user interfaces that are intuitive and easy to use.'
                            index='3'
                            expertises={visuals}
                        />

                    </div>

                    <WorkTogether />

                </BgSection>

            </div>

        </>
    )
}
