import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import BgSection from '../components/BgSection'
import Button from '../components/Button'

export default function Error() {


    return (
        <>
            <Helmet>
                <title>Page not found | Mees Akveld</title>
                <meta name="twitter:title" content="Page not found | Mees Akveld" />
				<meta property="og:title" content="Page not found | Mees Akveld" />
            </Helmet>

            <BgSection>
                <div className='h-full flex flex-col gap-6 min-h-[94vh] relative'>
                    <div className='absolute top-20 md:top-1/2 md:-translate-y-1/2 left-20 flex flex-col gap-8'>
                        <div>
                            <h1 className='font-passionOne uppercase text-8vw'>404 Not Found</h1>
                            <p>Sorry, the page you are looking for does not exist.</p>
                        </div>

                        <Button url='/' title="Go back to the homepage"></Button>
                    </div>
                </div>
            </BgSection>

        </>
    )
}
