/**
 * Returns the current date and time in a formatted string.
 * @returns {string} The formatted date and time. Format: "Di 30 jul 2021 15:30:00"
 */
export default function getCurrentTime() {
    const date = new Date();

    // Custom format options
    const options = {
        weekday: 'short',   // "Di"
        day: '2-digit',     // "30"
        month: 'short',     // "jul"
    };

    const formattedDate = date.toLocaleDateString('nl-NL', options);
    const formattedTime = date.toLocaleTimeString('nl-NL');

    return `${formattedDate} ${formattedTime}`;
}