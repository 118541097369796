import React from 'react'

// Components
import Card from './Card'

// Assets
import infoCircle from '../assets/icons/info.circle.svg'

export default function ExpertiseList({ title, description, index, expertises }) {
    return (
        <Card>
            <div className='flex flex-col gap-8'>
                <div className='flex flex-col gap-12'>
                    <div className='flex flex-col gap-2 items-center'>
                        <h2 className='uppercase font-passionOne text-8vw text-center' >{title}</h2>
                        <p className='aspect-square w-8 h-8 flex flex-col items-center justify-center rounded-full border-[1px] border-lightGray'>{index}</p>
                    </div>
                    <p className='text-center max-w-[30rem] mx-auto'>{description}</p>
                </div>
                <div className='flex flex-col gap-8' id={`${title.toLowerCase()}-container`}>
                    <hr className='border-lightGray' />

                    <div className='flex gap-8'>
                        <div className='pr-[2vw] border-r-[1px] border-lightGray max-md:hidden'>
                            <h3 data-scroll data-scroll-sticky data-scroll-target={`#${title.toLowerCase()}-container`} className='pl-4 text-[3vw] leading-[3vw] font-passionOne uppercase'>{title}</h3>
                        </div>

                        <ul>
                            {expertises.map((item, index) => (
                                <li key={index} className={`flex gap-4 ${index !== 0 ? 'pt-[2vw] my-[2vw] border-t-[1px] border-lightGray' : ''}`}>
                                    <h4 className='text-base md:text-xl w-1/5 font-normal'>{item.name}</h4>
                                    <div className='w-4/5 flex flex-col gap-4'>
                                        <p>{item.description}</p>
                                        {item.notes.length > 0 && (
                                            <ul className='list-inside'>
                                                {item.notes.map((note, index) => (
                                                    <li key={index}>
                                                        <div className='flex gap-2 italic items-center'>
                                                            <img src={infoCircle} alt="info" className='w-4 h-4 inline-block' />
                                                            <p>{note}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Card>
    )
}
