import card from '../../assets/images/projects/to-do-application/card.png';
import banner from '../../assets/images/projects/to-do-application/banner.png';
import showcaseWide1 from '../../assets/images/projects/to-do-application/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/to-do-application/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/to-do-application/showcase-wide-3.png';
import devider from '../../assets/images/projects/to-do-application/devider.png';
import mobile1 from '../../assets/images/projects/to-do-application/mobile-1.png';
import mobile2 from '../../assets/images/projects/to-do-application/mobile-2.png';
import mobile3 from '../../assets/images/projects/to-do-application/mobile-3.png';

export default {
    title: "To-Do Application",
    slug: 'to-do-application',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/to-do-application",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `Introducing Just Do It — The Ultimate To-Do App. Manage your tasks, organize categories, and stay on top of your day with ease.`,
        `With Just Do It, you can seamlessly track your tasks, create custom categories, and access your personalized to-dos anytime, anywhere. Effortlessly share your tasks via QR codes or email, making collaboration simple.`,
        `I'm excited to share that Just Do It enhances your productivity with instant email notifications for every new task or category added. Task management has never been this intuitive, ensuring you stay organized and efficient every step of the way.`
    ],
    developmentNotes: [
        { title: "Technology Stack", text: "The application is built using Node.js, Express, and SQLite3." },
        { title: "MVC Pattern", text: "The application is structured using the MVC pattern, ensuring a clean separation of concerns." },
        { title: "Knex.js", text: "Knex.js is used for database management, providing a smooth interface for interacting with SQLite3." },
        { title: "Nodemailer", text: "The nodemailer package is utilized to send emails to users for notifications and task updates." },
        { title: "API Functionality", text: "The application provides an API that allows users to manage their todos and categories with full CRUD operations." },
    ],
    mostProudOf: [
        `I'm really proud of how I managed to connect a database to my project for the first time. It was a big learning curve, but getting SQLite3 up and running felt like a huge achievement.`,
        `Another highlight for me was figuring out how to integrate QR code generation and use nodemailer system with the SQLite3 database. Combining these features to work together smoothly was challenging, but seeing it all come together in the final product was incredibly rewarding.`
    ],    
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}