import React from 'react'


/**
 * Renders a header element with a title and children.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the header element.
 * @param {ReactNode} props.children - The children elements to be rendered inside the header element.
 * @returns {JSX.Element} The rendered header element.
 */
export default function FooterElement({ title, children, className='', classNameForContainer='' }) {
    return (
        <section className={`bg-lightBlue rounded-[10px] flex flex-col gap-0 w-full overflow-hidden ${classNameForContainer}`} title={title}>
            <div className='p-2 lg:p-1 pl-4 lg:pl-2 border-b-2 border-blue text-[14px]'>
                <p className='text-veryLightBlue'>{title}</p>
            </div>
            <div className={`p-4 relative flex justify-center items-center ${className}`}>
                {children}
            </div>
        </section>
    )
}
