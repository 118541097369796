import card from '../../assets/images/projects/todays-dish/card.png';
import banner from '../../assets/images/projects/todays-dish/banner.png';
import showcaseWide1 from '../../assets/images/projects/todays-dish/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/todays-dish/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/todays-dish/showcase-wide-3.png';
import devider from '../../assets/images/projects/todays-dish/devider.png';
import mobile1 from '../../assets/images/projects/todays-dish/mobile-1.png';
import mobile2 from '../../assets/images/projects/todays-dish/mobile-2.png';
import mobile3 from '../../assets/images/projects/todays-dish/mobile-3.png';

export default {
    title: "Today's Dish",
    slug: 'todays-dish',
    platform: 'mobile', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: "https://apps.apple.com/app/todays-dish/id6473428743",
    },
    web: {
        url: null,
    },
    description: [
        `Introducing <span class="font-medium">Today's Dish</span> — The Ultimate Recipe App. Discover new recipes, create shopping lists, and plan your meals with ease.`,
        `With Today's Dish, you can easily browse through a wide variety of recipes, save your favorites, and create a shopping list with just a few taps. `,
        `I'm proud to announce that Today's Dish has won the Swift Student Challenge 2023 from Apple. With Today's Dish, cooking has never been easier. Our app ensures that you have all the tools needed to create delicious meals that your whole family will love.`
    ],
    developmentNotes: [
        { title: "Technology Stack", text: "The app is developed using SwiftUI for the user interface and CloudKit for backend services." },
        { title: "SwiftUI", text: "Utilized for creating a responsive and visually appealing user interface." },
        { title: "CloudKit", text: "Used for secure and seamless data storage and synchronization across devices." },
    ],
    mostProudOf: [
        `This was my first React project, and I'm very proud of the design. As a programming student, I found it a very cool experience to start from a blank canvas as an exercise and end up with a functional result. I particularly enjoyed this because it allowed me to <span class="font-medium">strategically plan</span> from the outset to ensure a smooth workflow later on.`,
        `Therefore, I focused on recurring <span class="font-medium">components and design elements</span> in the design phase so that I could you the components later in the development and perfectly knew where what needed to be and how I could make them as <span class="font-medium">optimized</span> as possible.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}