import React from 'react'

/**
 * Card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the card.
 * @param {string} props.description - The description of the card.
 * @param {ReactNode} props.children - The content of the card.
 * @returns {JSX.Element} The rendered Card component.
 */
export default function Card({ title, description, children, className = "" }) {
    return (
        <div className='relative flex flex-col gap-2'>
            {title &&
                <div
                    className="
                    bg-blue text-white uppercase text-sm rounded-[40px] py-3 px-5 text-center font-normal w-full relative
                    sm:absolute sm:indent-top-width
                "
                >
                    <p className='truncate'>{title}</p>
                </div>
            }

            <div className={`flex flex-col gap-6 p-6 bg-gray rounded-[30px] ${title ? 'pt-5 sm:indent-top' : ''}`}>
                {description &&
                    <p className={`
                    leading-[200%]
                    sm:leading-[270%] 
                    ${title ? 'sm:indent-80' : ''}
                `}>
                        {description}
                    </p>
                }

                {children &&
                    <div className={`${className}`}>
                        {children}
                    </div>
                }
            </div>

        </div>
    )
}
