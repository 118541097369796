import card from '../../assets/images/projects/pgm-platform/card.png';
import banner from '../../assets/images/projects/pgm-platform/banner.png';
import showcaseWide1 from '../../assets/images/projects/pgm-platform/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/pgm-platform/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/pgm-platform/showcase-wide-3.png';
import devider from '../../assets/images/projects/pgm-platform/devider.png';
import mobile1 from '../../assets/images/projects/pgm-platform/mobile-1.png';
import mobile2 from '../../assets/images/projects/pgm-platform/mobile-2.png';
import mobile3 from '../../assets/images/projects/pgm-platform/mobile-3.png';

export default {
    title: "PGM Platform",
    slug: 'pgm-platform',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/pgm-platform",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: "https://pgm-platform-pgm-meesakveld.onrender.com/",
    },
    description: [
        `The Associate Degree Programming website is an extensive platform built with React and Tailwind CSS, using Hygraph Headless CMS for backend services.`,
        `The site targets a diverse audience, including prospective students, current students, alumni, industry professionals, and other interested parties.`,
        `Styled with Tailwind CSS for a modern look, and optimized for SEO using React-Helmet to include meta tags and titles.`
    ],
    developmentNotes: [
        { title: "React & Tailwind CSS", text: "The platform is built using React for a dynamic user interface and Tailwind CSS for modern, responsive design." },
        { title: "Hygraph Headless CMS", text: "Utilized Hygraph Headless CMS for backend services, enabling flexible content management and integration." },
        { title: "SEO Optimization", text: "Implemented React-Helmet to manage meta tags and titles, enhancing SEO friendliness and improving search engine visibility." },
        { title: "Design Strategy", text: "Focused on designing reusable components and strategic layout planning to streamline development and optimize workflow." }
    ],
    mostProudOf: [
        `I’m especially proud of this being my first React project and the overall design that resulted from it. Starting from a blank canvas and creating a functional and visually appealing platform was a rewarding experience.`,
        `I particularly enjoyed planning the design and component structure in advance, which allowed me to develop a well-organized and optimized project. This strategic approach ensured a smooth development process and a polished final product.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}