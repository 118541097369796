import React from 'react'
import Card from './Card'
import Button from './Button'

export default function WorkTogether() {
    const links = [
        { title: 'E-mail', url: 'mailto:meesakveld@gmail.com' },
        { title: 'Github', url: 'https://www.github.com/meesakveld' },
        { title: 'LinkedIn', url: 'https://www.linkedin.com/in/meesakveld' },
        { title: 'Instagram', url: 'https://www.instagram.com/meesakveld' },
    ]

    return (
        <div className='flex flex-col gap-4 mt-8'>
            <Card className="p-6 flex flex-col gap-6 max-sm:py-8">
                <h2 className='font-passionOne uppercase text-8vw'>Work Together</h2>
                <div className='flex flex-col justify-between gap-4 pb-4 sm:flex-row sm:items-end'>
                    <p className='sm:max-w-1/3'>Let's embark on an exciting project together! If you're intrigued, press the button and tell me your vision. I'm confident we can make it outstanding!</p>
                    <Button title="Start a project!" url="/contact" bgColor="blue" />
                </div>
            </Card>

            <Card className="p-2">
                <ul className='uppercase w-full grid grid-cols-2 md:flex gap-3 md:gap-12 justify-items-center md:justify-center'>
                    {links.map((link, index) => (
                        <li key={index}>
                            <a className='lined' href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
                        </li>
                    ))}
                </ul>
            </Card>
        </div>
    )
}
