import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Pages
import Root from './layouts/Root';
import { Welcome, About, Projects, Project, Photography, Expertise, Contact, Legal, Error } from './pages';

// Styles
import './styles/output.css';

// Functions
function RedirectToProjects() {
	const { slug } = useParams();
	return <Navigate to={`/projects/${slug}`} replace />;
}

// Router
const routes = createBrowserRouter(createRoutesFromElements(
	<>
		<Route path='/' element={<Root />} errorElement={<Error />}>
			<Route path='/' element={<Welcome />} />
			<Route path='/about' element={<About />} />
			<Route path='/projects' element={<Projects />} />
			<Route path='/projects/:slug' element={<Project />} />
			<Route path='/photography' element={<Photography />} />
			<Route path='/expertise' element={<Expertise />} />
			<Route path='/contact' element={<Contact />} />
		</Route>

		{/* Legal */}
		<Route path='/legal/:type/:slug' element={<Legal />} />

		{/* Redirecting urls */}
		<Route path='/legal' element={<Navigate to='/' replace />} />
		<Route path='/legal/*' element={<Navigate to='/' replace />} />

		{/* —— Temporary Redirects —— */}
		<Route path='/my-work/applications/:slug' element={<RedirectToProjects />} />

	</>
))

function App() {

	return (
		<>
			<Helmet>
				<title>Mees Akveld</title>
				<meta name="twitter:title" content="Mees Akveld" />
				<meta property="og:title" content="Mees Akveld" />
				<meta property="og:url" content="url" />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="website" />
				<meta
					name="description"
					content="Transforming ideas into digital reality. Hi, I'm Mees, I'm passionate about designing, creating, and developing digital projects."
				/>
				<meta 
					property="og:description" 
					content="Transforming ideas into digital reality. Hi, I'm Mees, I'm passionate about designing, creating, and developing digital projects." 
				/>
				<meta 
					name="twitter:description" 
					content="Transforming ideas into digital reality. Hi, I'm Mees, I'm passionate about designing, creating, and developing digital projects." 
				/>
				<meta
					name="keywords"
					content="Digital World, Programming, Designing, Creating, Developing digital projects, Mees Akveld,"
				/>
				<meta name="google-site-verification" content="O2duFN8SvmNgfbF8xVPI8yEzX48laYt0PtBDr665KKU" />

			</Helmet>

			<RouterProvider router={routes} />
		</>
	);
}

export default App;
