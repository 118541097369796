import React, { useRef, useEffect, useState } from 'react'

// Functions
import getAlignment from '../functions/getAlignment';
import getInstagramPosts from '../functions/getInstagramPosts';
import cleanInstagramCaption from '../functions/cleanInstagramCaption';

// GSAP
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

// Components
import BgSection from '../components/BgSection'
import Title from '../components/Title'
import Card from '../components/Card'
import Button from '../components/Button'
import ShowcaseCard from '../components/ShowcaseCard'
import WorkTogether from '../components/WorkTogether'

// Assets
import person from '../assets/images/person.svg'
import mountain from '../assets/images/mountain.svg'
import brain from '../assets/images/brain.svg'
import expertise from '../assets/images/expertise.svg'
import handWithBee from '../assets/images/hand-with-bee.svg'
import homeDevider from '../assets/images/home-devider.jpeg'
import aboutMeImage from '../assets/images/about-me-image.jpeg'
import circleStroke from '../assets/images/circle-stroke.svg'

// Data
import projects from '../data/projects'

export default function Welcome() {

    // —————— Section 1: Welcome ——————
    const hobbies = [
        { title: 'Lifestyle', keywords: ['Traveling', 'Exploring', 'Nature'] },
        { title: 'Well-being', keywords: ['Health', 'Food'] },
        { title: 'Development', keywords: ['Websites', 'Apps', 'Design'] },
        { title: 'Culture', keywords: ['Music', 'Art'] }
    ]

    const welcomeSection = useRef();
    useGSAP(
        () => {
            const timeline = gsap.timeline();

            timeline
                .fromTo(
                    '.up',
                    { y: '100%' },
                    { y: '0%', duration: 2, ease: 'power4.out' }
                )
                .fromTo(
                    '.up2',
                    { y: '100%' },
                    { y: '0%', duration: 1.5, ease: 'power4.out' }, '<0.55'
                )
                .fromTo(
                    '.up3',
                    { y: '100%' },
                    { y: '0%', duration: 1.5, ease: 'power4.out' }, '<0.35'
                );
        }, { scope: welcomeSection }
    );

    // —————— Photography ——————
    const [posts, setPosts] = useState([]);
    const [activePostIndex, setActivePostIndex] = useState(0);
    useEffect(() => {
        const getPosts = async () => {
            const data = await getInstagramPosts(10);
            setPosts(data.data);
            setActivePostIndex(0);
        }
        getPosts();
    }, [])
    const nextPost = () => {
        setActivePostIndex((activePostIndex + 1));
    }
    const prevPost = () => {
        setActivePostIndex((activePostIndex - 1));
    }


    return (
        <div className='flex flex-col gap-0'>
            <BgSection className="pt-24 md:pt-24 flex flex-col gap-20 z-10">

                {/* Introductions */}
                <section ref={welcomeSection} className='min-h-[100vh] max-lg:relative flex flex-col gap-8 lg:gap-4'>
                    <h1 className='hidden'>Mees Akveld</h1>
                    <div className='font-passionOne uppercase lg:h-[29vw] lg:relative' >
                        <p className='overflow-hidden text-[47vw] leading-[33vw] lg:text-[30vw] lg:leading-[21vw]'>
                            <div className='up'>Mees</div>
                        </p>
                        <p className='overflow-hidden text-[32.5vw] leading-[23vw] lg:text-[20vw] lg:leading-[15vw] lg:text-right lg:mix-blend-multiply lg:top-[-5vw] relative'>
                            <div className='up'>Akveld</div>
                        </p>
                        <img data-scroll data-scroll-speed="1.25" className='absolute max-lg:opacity-50 mix-blend-multiply pointer-events-none -right-[40%] bottom-[15%] lg:top-[-20%] lg:right-[18%] lg:h-[30vw]' src={person} alt="Mees Akveld" />
                    </div>

                    <div className='flex flex-col gap-8 lg:gap-4 max-lg:mb-10'>
                        <div>
                            <div className='overflow-hidden font-dmSerifDisplay italic text-lightBlue'>
                                <p className='up2' style={{ fontSize: '9vw', lineHeight: '9vw' }}>I Am Passionate About</p>
                            </div>
                        </div>

                        <div>
                            <ul className='grid grid-cols-2 max-lg:gap-4 max-lg:text-[1.3rem]'>
                                {hobbies.map((hobby, index) => (
                                    <li key={index} className='sm:flex gap-4'>
                                        <div className="overflow-hidden font-dmSerifDisplay">
                                            <p className='up3'>{hobby.title}</p>
                                        </div>
                                        <ul className='sm:flex gap-4'>
                                            {hobby.keywords.map((keyword, index) => (
                                                <li key={index} className='overflow-hidden font-light'>
                                                    <p className="up3">{keyword}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                <div className='flex flex-col gap-32'>

                    {/* Portfolio */}
                    <section className='flex flex-col gap-12'>
                        <Title title='Portfolio' titlePrt1='Port' titlePrt2='Folio' titleH='h2' imgPath={mountain} imgClassName="h-[50vw]" />

                        <div className='flex flex-col gap-4'>
                            <Card title="What i've been doing" description="Since junuari 2023, I've found my passion for developing digital projects. First I started with Mobile Development and after loving that so much, I started in september 2023 at Artevelde University of Applied Sciences to become a Full-Stack Developer." >
                                <div className='flex flex-col gap-4 sm:flex-row sm:gap-0 sm:justify-between mt-8 sm:mb-8'>
                                    <h3 className='text-[4vw] font-[400] leading-[100%]' style={{ fontSize: 'clamp(1vw, 4vw, 2rem)' }}>Projects</h3>
                                    <div className='flex flex-col gap-12 sm:w-1/2'>
                                        <div className='flex flex-col gap-4'>
                                            <p>Today, I'm a price winning mobile developer. Always looking for opportunities to learn. With my strategic mindset and strong organizational skills, I ensure that I deliver high-quality results.</p>
                                            <p>Being honest is very important to me, and I uphold this value in every situation. I am very eager to learn and enjoy discovering new things every day.</p>
                                        </div>
                                        <Button title="See all projects" bgColor='blue' url="/projects" />
                                    </div>
                                </div>
                            </Card>

                            <div className='flex flex-col sm:grid grid-cols-2 gap-4'>
                                {projects.slice(0, 3).map((project, index) => (
                                    <ShowcaseCard key={`project-${index}`} title={project.title} image={project.images.card.src} imageAlt={project.images.card.src} url={`/projects/${project.slug}`} platform={project.platform} alignment={getAlignment(index, projects.length)} className={`${getAlignment(index, projects.slice(0, 3).length) === 'horizontal' ? 'col-span-2' : 'col-span-1'}`} />
                                ))}
                            </div>

                        </div>

                    </section>

                    {/* Expertise */}
                    <section className='flex flex-col gap-12 mb-8'>
                        <Title title='Expertise' titlePrt1='Exper' titlePrt2='tise' titleH='h2' imgPath={brain} imgClassName="h-[60vw]" />

                        <div className='flex flex-col md:flex-row gap-4 h-full md:h-[55vw]'>
                            <div className='bg-gray rounded-[30px] p-12 md:w-2/3 flex flex-col gap-4 md:gap-8 h-full'>
                                <h3 className='font-passionOne uppercase w-2/3' style={{ fontSize: 'clamp(3rem, 6vw, 6vw', lineHeight: 'clamp(3rem, 6vw, 6vw' }}>Expertise & Skills</h3>

                                <div className='h-full'></div>

                                <div className='flex flex-col max-md:gap-6 md:flex-row w-full md:items-center md:border-y-[1px] border-[#C1C3C9]'>
                                    <p className='text-[0.8rem] md:py-8 md:pr-8 md:border-r-[1px] border-[#C1C3C9]'>As a developer, I create websites and apps for both mobile devices and desktops. My projects range from simple web applications to complex platforms that process large amounts of data. I aim to deliver innovative and user-friendly solutions.</p>
                                    <div className='md:p-8'>
                                        <Button title="See all expertises" bgColor='blue' url="/expertise" />
                                    </div>
                                </div>
                            </div>

                            <div className='bg-gray rounded-[30px] p-12 m-auto w-full md:w-1/3 h-full pointer-events-none'>
                                <img className='w-full' src={expertise} alt="Development, Visuals & Technologies → Memorable experience" />
                            </div>

                        </div>

                    </section>

                </div>

            </BgSection>

            <div data-scroll data-scroll-speed="1.5" className='h-[50rem] w-full relative -top-40' style={{ transform: 'translateY(-20rem)' }}>
                <img className='w-full h-[70rem] object-cover pointer-events-none' src={homeDevider} alt="Swedish Ferry" />
                <div data-scroll data-scroll-speed="1.25" className='absolute top-[70%] left-4 lg:left-16 flex flex-col gap-4' style={{ transform: 'translateY(-50%)' }}>
                    <img className='rounded-[30px] min-w-[13rem] w-[22vw] pointer-events-none' src={aboutMeImage} alt="" />
                    <Button title="About Me" bgColor='white' url="/about" />
                </div>
            </div>

            <BgSection className="flex flex-col gap-40 z-10">

                {/* Photography */}
                <section className='flex flex-col gap-16 mt-16'>
                    <Title title='Photography' titlePrt1='Photo' titlePrt2='graphy' titleH='h2' imgPath={handWithBee} imgClassName="h-[50vw]" />

                    <div className='flex flex-col gap-28'>
                        <div className='relative'>
                            <div className='absolute left-0 top-0'>
                                <Button title="Capturing beauty" bgColor='blue' />
                            </div>
                            <p className='leading-[240%] relative top-1' style={{ textIndent: 'clamp(16rem, 24.5vw, 24.5vw' }} >For me, photography is an art of capturing the essence while leaving enough room for imagination and creativity. It is a method to log memorable moments and cherish them. What I find interesting is that every photo can bring back a memory, and remarkably enough, it might evoke a different memory for someone else than it does for me. It's a personal journey for each image that is created.</p>
                        </div>

                        <div className='relative'>
                            <img className='absolute top-1/2 w-full' style={{ transform: 'translateY(-50%)' }} src={circleStroke} alt="Decoration" />
                            <div className='relative w-[50vw] lg:w-[40vw] mx-auto'>
                                <button onClick={() => { if (activePostIndex !== 0) prevPost() }} className='w-5 h-5 absolute -left-12 top-[11vw] transition-all duration-300 hover:scale-[1.5] flex flex-col items-center justify-center' style={{ opacity: activePostIndex === 0 ? '0' : '1' }}><div className='w-2 h-2 border-blue rounded-full border-[1px]'></div></button>
                                <button onClick={() => { if (activePostIndex !== 9) nextPost() }} className={`w-5 h-5 absolute -right-12 top-[11vw] transition-all duration-300 hover:scale-[1.5] flex flex-col items-center justify-center`} style={{ opacity: activePostIndex === 9 ? '0' : '1' }}><div className='w-2 h-2 border-blue rounded-full border-[1px]'></div></button>
                                <p className='absolute top-0 -right-16'><span className='font-bold'>{activePostIndex + 1}</span>/10</p>
                                <div className='relative flex flex-col gap-8'>
                                    {posts.map((post, index) => (
                                        <div key={`instagram-post-${index}`} className={`transition-opacity duration-500 ${activePostIndex === index ? 'flex' : 'hidden pointer-events-none'} flex flex-col gap-12 items-center w-full`}>
                                            <img
                                                className={`pointer-events-none h-[45vw] max-h-[25rem] object-contain`}
                                                src={post.media_url}
                                                alt={post.caption}
                                            />
                                            <p className='text-center m-auto min-h-16'>{cleanInstagramCaption(post.caption)}</p>
                                        </div>

                                    ))}
                                    <div className='w-full flex flex-col items-center'>
                                        <Button title="See all photos" bgColor='white' url="/photography" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {/* Work Together */}
                <WorkTogether />

            </BgSection>

        </div>
    )
}
