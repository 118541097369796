export default (index, total) => {
    const basePattern = ['horizontal', 'vertical', 'vertical'];

    // Calculate the full pattern length and where we are in it
    const patternIndex = index % 3;

    // When total is exactly divisible by 3, or larger sections, just return based on base pattern
    if (total % 3 === 0 || index < total - 2) {
        return basePattern[patternIndex];
    }
    
    // If total % 3 === 1 and it's the last element, it should be 'horizontal'
    if (total % 3 === 1 && index === total - 1) {
        return 'horizontal';
    }

    // If total % 3 === 2 and it's one of the last two, return 'horizontal'
    if (total % 3 === 2 && index >= total - 2) {
        return 'horizontal';
    }

    // Default case (shouldn't be reached)
    return basePattern[patternIndex];
};
