import React from 'react'

/**
 * Renders a background section component.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the section.
 * @returns {JSX.Element} The rendered background section component.
 */
export default function BgSection({className="", children}) {
    return (
        <section className={`bg-noice rounded-[20px] md:rounded-[40px] p-3 md:p-4 overflow-hidden ${className}`}>
            {children}
        </section>
    )
}

