import card from '../../assets/images/projects/structure/card.png';
import banner from '../../assets/images/projects/structure/banner.png';
import showcaseWide1 from '../../assets/images/projects/structure/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/structure/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/structure/showcase-wide-3.png';
import devider from '../../assets/images/projects/structure/devider.png';
import mobile1 from '../../assets/images/projects/structure/mobile-1.png';
import mobile2 from '../../assets/images/projects/structure/mobile-2.png';
import mobile3 from '../../assets/images/projects/structure/mobile-3.png';

export default {
    title: "Structure",
    slug: 'structure',
    platform: 'mobile', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: "https://apps.apple.com/app/structure/id6502792410",
    },
    web: {
        url: null,
    },
    description: [
        `Introducing Structure — The Ultimate Travel App with Kids. Transform your holidays into a cherished tradition with Structure.`,
        `Plan daily activities that your child can look forward to, providing a sense of structure and excitement for each new day. `,
        `At the end of the day, your child can reflect on their favorite moments and share them with you, creating a lasting memory that you can cherish together.`,
        `I'm proud to announce that Structure has won the Swift Student Challenge 2024 from Apple. With Structure, holidays are more than just trips; they are a journey of fun, learning, and growth. Our app ensures that you have all the tools needed to make every holiday unforgettable.`
    ],
    developmentNotes: [
        { title: "Technology Stack", text: "The app is developed using SwiftUI for the user interface and AppStorage for data storage." },
        { title: "SwiftUI", text: "Utilized for creating a responsive and visually appealing user interface." },
        { title: "AppStorage", text: "Used for secure and seamless data storage on your device." },
    ],
    mostProudOf: [
        `For me, Structure is a project that I'm very proud of. It was a great experience to work on a project that is not only fun but also helpful. I enjoyed the challenge of creating an app that is both engaging and helpfull for parents with children.`,
        `I'm particularly proud of the <span class="font-bold">design</span> of the app. I wanted to create an app that is not only visually appealing but also easy to use. I focused on creating a design that is simple and intuitive, so that it wasn't be overwhelming for parents and children.`,
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}