import card from '../../assets/images/projects/student-tracking-system/card.png';
import banner from '../../assets/images/projects/student-tracking-system/banner.png';
import showcaseWide1 from '../../assets/images/projects/student-tracking-system/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/student-tracking-system/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/student-tracking-system/showcase-wide-3.png';
import devider from '../../assets/images/projects/student-tracking-system/devider.png';
import mobile1 from '../../assets/images/projects/student-tracking-system/mobile-1.png';
import mobile2 from '../../assets/images/projects/student-tracking-system/mobile-2.png';
import mobile3 from '../../assets/images/projects/student-tracking-system/mobile-3.png';

export default {
    title: "Student Tracking System",
    slug: 'student-tracking-system',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/student-tracking-system",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `The Student Tracking System (Student Volgsysteem in NL) is a web application designed to monitor students throughout their education, including lectures, practical sessions, coaching, and workplace learning.`,
        `The objective is to enable effective monitoring by teachers, learning coaches, program coaches, diversity coaches, and team leaders. Developed on commission by Arteveldehogeschool, the system includes functionalities such as recording attendance, evaluating participation, assessing performance, documenting coaching information, and tracking student status.`,
        `This project was a collaborative effort involving myself and three other classmates. I served as the project manager with a focus on the back-end, managing tasks through GitHub Projects, handling merge requests, and acting as the main contact for our client.`
    ],
    developmentNotes: [
        { title: "Project Scope", text: "The system tracks various aspects of student performance and engagement, including attendance, participation, performance on assignments, coaching documentation, and overall student status." },
        { title: "Collaborative Effort", text: "The project was completed as a team effort with three classmates, where I took on the role of project manager and focused on back-end development." },
        { title: "Project Management", text: "Used GitHub Projects to manage tasks and handle merge requests, ensuring smooth project execution and communication." }
    ],
    mostProudOf: [
        `I am most proud of my team. Working together can be challenging, especially in a school setting, but we demonstrated strong collaboration and teamwork.`,
        `I am particularly proud of experiencing a highly effective team dynamic, where we built on each other's strengths, worked harmoniously, and learned from one another. That experience of working in such a cohesive and supportive team is what I am most proud of!`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}