import card from '../../assets/images/projects/multus-timers/card.png';
import banner from '../../assets/images/projects/multus-timers/banner.png';
import showcaseWide1 from '../../assets/images/projects/multus-timers/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/multus-timers/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/multus-timers/showcase-wide-3.png';
import devider from '../../assets/images/projects/multus-timers/devider.png';
import mobile1 from '../../assets/images/projects/multus-timers/mobile-1.png';
import mobile2 from '../../assets/images/projects/multus-timers/mobile-2.png';
import mobile3 from '../../assets/images/projects/multus-timers/mobile-3.png';

export default {
    title: "Multus Timers",
    slug: 'multus-timers',
    platform: 'mobile', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: "https://apps.apple.com/app/multus-timers/id6464059331",
    },
    web: {
        url: null,
    },
    description: [
        `Multus Timers: Your Ultimate Time Management App!`,
        `Are you tired of juggling multiple timers and constantly switching between apps to manage your time efficiently? Look no further! Multus Timers is here to assist you with all your time-related tasks and more.`,
        `With Multus Timers, you can effortlessly use multiple timers simultaneously. No more dealing with complex calculations or confusing interfaces. Our intuitive app allows you to activate and manage multiple timers concurrently, enabling you to multitask seamlessly without losing track.`,
        `Multus Timers also lets you set up custom presets in advance using AppStorage. You can give your presets custom names and icons, making them easily distinguishable and ready for use whenever you need them. Say goodbye to manually setting timers for repetitive activities.`,
        `Furthermore, you can fully customize your app experience with various color themes and choose between dark or light mode, depending on your preference and environment.`,
        `Ready to manage your time more efficiently and be more productive? Download Multus Timers now and discover how this powerful app can help you plan your tasks better and simplify your daily life. Don’t miss this opportunity to become the ultimate time manager!`
    ],
    developmentNotes: [
        { title: "Multiple Timers", text: "Implemented functionality to manage multiple timers simultaneously, allowing users to multitask effectively without confusion." },
        { title: "Custom Presets", text: "Utilized AppStorage to create and store custom presets. Users can assign names and icons to these presets for quick and easy access to commonly used timers." },
        { title: "Customization", text: "Added various color themes and options for dark or light mode, enabling users to personalize their app experience and match their visual preferences." },
        { title: "User Interface", text: "Designed an intuitive interface to simplify timer management and improve usability, ensuring users can effortlessly navigate and utilize the app's features." }
    ],
    mostProudOf: [
        `I’m particularly proud of implementing the custom presets feature using AppStorage. It allowed users to save and quickly access frequently used timer settings, making repetitive tasks much easier.`,
        `Additionally, I'm thrilled with the customization options provided, including color themes and dark/light mode. This personal touch enhances user experience by allowing them to tailor the app to their preferences and environment.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}