import React, { useRef } from 'react'
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

export default function Intro({ titlePrt1, titlePrt2, subtitle }) {

    const introContainer = useRef();
    useGSAP(
        () => {
            const timeline = gsap.timeline();

            timeline.fromTo('.title1', {
                clipPath: 'inset(0 0 100% 0)',
                transform: 'translateY(100%)',
            }, {
                clipPath: 'inset(0 0 -100% 0)',
                transform: 'translateY(0)',
                duration: 1.5,
                ease: 'power4.out',
                delay: 0.25,
            });

            timeline.fromTo('.title2', {
                clipPath: 'inset(0 0 100% 0)',
                transform: 'translateY(100%)',
            }, {
                clipPath: 'inset(0 0 -100% 0)',
                transform: 'translateY(0)',
                duration: 1.5,
                ease: 'power4.out',
            }, '<0.55');

        }, { scope: introContainer }
    );

    return (
        <div ref={introContainer} className='w-max m-auto flex flex-col justify-center relative -top-20' style={{height: '90vh'}}>
            <p className='uppercase'>{subtitle}</p>
            <div className='font-passionOne uppercase text-13vw md:text-10vw text-darkBlue overflow-hidden'>
                <p className='title1'>{titlePrt1}</p>
                <p className='title2'>{titlePrt2}</p>
            </div>
        </div>
    )
}
