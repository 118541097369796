import React, { useState, useEffect } from 'react'
import getCurrentTime from '../functions/getCurrentTime'
import { getContributions } from '../functions/getGithubContributions'

import FooterElement from './FooterElement'

import duolingoLogo from '../assets/images/duolingo-logo.png'
import githubLogo from '../assets/images/github-logo.png'
import { Link } from 'react-router-dom'


export default function Footer() {
    // Current date and time
    const [currentTimeAndDate, setCurrentTimeAndDate] = useState(null);
    useEffect(() => {
        setCurrentTimeAndDate(getCurrentTime());
        setInterval(() => {
            setCurrentTimeAndDate(getCurrentTime());
        }, 1000);
    }, [])

    // Days I've been learning Swedish on Duolingo
    const [duolingoStreak, setDuolingoStreak] = useState('...')
    useEffect(() => {
        const startDate = new Date('2024-07-07')
        const currentDate = new Date()
        const timeDifference = currentDate.getTime() - startDate.getTime()
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
        setDuolingoStreak(daysDifference)
    }, [])

    // Api call to github for the amount of contributions I've made
    const [githubCommits, setGithubCommits] = useState('...')
    useEffect(() => {
        async function init() {
            const acc1Data = await getContributions(process.env.REACT_APP_GITHUB_TOKEN_ACC_1, process.env.REACT_APP_GITHUB_USERNAME_ACC_1)
            const acc2Data = await getContributions(process.env.REACT_APP_GITHUB_TOKEN_ACC_2, process.env.REACT_APP_GITHUB_USERNAME_ACC_2)
            setGithubCommits(acc1Data + acc2Data)
        }
        init()
    }, [])

    // Current weather in Ghent
    const [currentWeather, setCurrentWeather] = useState(null);
    useEffect(() => {
        fetch(process.env.REACT_APP_WEATHER_API_URL)
            .then(response => response.json())
            .then(data => {
                setCurrentWeather(data.current.temp_c)
            })
    }, []);

    // Reach out if
    const reasonsToReachOut = ['You have a cool project.', 'You have an idea.', 'You have a cool proposal.', 'You have a question.', 'You have a suggestion.'];
    const [translateX, setTranslateX] = useState(0);
    useEffect(() => {
        setInterval(() => {
            setTranslateX((prevTranslateX) =>
                prevTranslateX === (reasonsToReachOut.length - 1) * 100
                    ? 0
                    : prevTranslateX + 100
            );
        }, 5000);
    }, [reasonsToReachOut.length]);

    // Pages
    const pages = [
        { title: 'Home', link: '/' },
        { title: 'About', link: '/about' },
        { title: 'Projects', link: '/projects' },
        { title: 'Photography', link: '/photography' },
        { title: 'Expertise', link: '/expertise' },
        { title: 'Contact', link: '/contact' }
    ]


    return (
        <footer className='flex flex-col gap-4 mt-10'>
            <div className='flex flex-col gap-3 lg:flex-row'>
                {/* Current time */}
                <FooterElement title='Current time' className='min-h-16 relative lg:py-8 h-full'>
                    <p className='text-white text-center'>{currentTimeAndDate}</p>
                </FooterElement>

                <div className='flex gap-3 lg:flex-shrink-0 lg:w-1/5'>
                    {/* Learning Swedish For */}
                    <FooterElement title='Learning Swedish for' className="lg:py-8 h-full">
                        <img className="mix-blend-luminosity w-4/6" src={duolingoLogo} alt="Duolingo Logo" />
                        <div className='flex text-white gap-1 absolute'>
                            <p className='text-[33px]'>{duolingoStreak}</p>
                            <p className='text-[13px]' style={{ transform: 'translateY(-10px)' }}>days</p>
                        </div>
                    </FooterElement>

                    {/* Github Commits */}
                    <FooterElement title='Contributions in the last year' classNameForContainer="hidden min-[450px]:block lg:hidden" className="">
                        <div className='h-full'>
                            <img className="p-8 w-full h-full" src={githubLogo} alt="Github Logo" />
                        </div>
                        <div className='flex absolute text-white gap-1'>
                            <p className='text-[33px]'>{githubCommits}</p>
                            <p className='text-[13px]' style={{ transform: 'translateY(-10px)' }}>contributions</p>
                        </div>
                    </FooterElement>
                </div>

                {/* Reach out if */}
                <FooterElement title='Reach out if' className="min-h-32 lg:py-8 h-full flex flex-col gap-2 px-0">
                    <ul className='w-full flex items-center transition-all ease duration-1000' style={{ transform: `translateX(-${translateX}%)` }}>
                        {reasonsToReachOut.map((reason, index) => {
                            return (
                                <li title={`Reach out if ${reason.toLowerCase()}`} className='w-[100%] shrink-0 text-center' key={`reachOut-${index}`}>
                                    <a className='text-white text-[14px]' href="mailto:meesakveld@gmail.com">{reason}</a>
                                </li>
                            )
                        })}
                    </ul>
                    <ul className='flex gap-1'>
                        {reasonsToReachOut.map((reason, index) => {
                            return (
                                <li key={`dot-${index}`} className='w-1 h-1 transition-all ease duration-1000 opacity-20 bg-white block rounded-full' style={{ opacity: `${translateX / 100 === index ? 1 : 0.2}` }}></li>
                            )
                        })}
                    </ul>
                </FooterElement>

                {/* Current weather in Ghent */}
                <FooterElement title='Current weather in Ghent' className="min-h-32 lg:py-8 h-full">
                    <div className='flex text-white gap-1'>
                        <p className='text-[33px]'>{currentWeather}</p>
                        <p className='text-[13px]' style={{ transform: 'translateY(-10px)' }}>°C</p>
                    </div>
                </FooterElement>

                {/* Github Commits */}
                <FooterElement title='Contributions in the last year' classNameForContainer="min-[450px]:hidden lg:block" className="">
                    <div>
                        <img className="p-8 w-full h-full" src={githubLogo} alt="Github Logo" />
                    </div>
                    <Link to='https://github.com/meesakveld' target='_blank' className='flex absolute text-white gap-1'>
                        <p className='text-[33px]'>{githubCommits}</p>
                        <p className='text-[13px]' style={{ transform: 'translateY(-10px)' }}>contributions</p>
                    </Link>
                </FooterElement>
            </div>

            {/* Links */}
            <div className='border-t-[1px] border-b-[1px] border-lightBlue py-4'>
                <ul className='grid grid-cols-2 text-center font-extralight lg:bg-lightBlue lg:rounded-[10px] lg:p-20 lg:flex lg:justify-center lg:gap-12'>
                    {pages.map((page, index) => {
                        return (
                            <li key={`page-${index}`} className='bg-lightBlue m-[3px] p-2 rounded-[10px]'>
                                <Link title={page.title} to={page.link} className='text-white uppercase lined'>{page.title}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>

            {/* Mees Akveld */}
            <div title='Mees Akveld' className='font-passionOne text-white uppercase mb-8'>
                <p className='text-center text-[19.4vw] leading-[14.4vw]'>Mees Akveld</p>
            </div>

        </footer>
    )
}
