/**
 * Fetches Instagram posts from the Instagram Graph API
 * @param {number} limit (optional) - Limit the number of posts to return
 * @returns { Promise<{
 * data: [{
*      id: string,
*      media_type: string,
*      caption: string,
*      media_url: string,
*      timestamp: string,
*      username: string,
*  }],
*  paging: {
*      cursors: {
*          before: string,
*          after: string
*      },
*      next: string,
*      previous: string
*  },
*  }> } Array of Instagram posts
*/
export default async (limit) => {
    const res = await fetch(
        `https://graph.instagram.com/me/media?fields=id,media_type,caption,media_url,username,timestamp&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}${limit ? `&limit=${limit}` : ''}`
    );
    let data = await res.json();
    data.data = data.data.filter(post => post.media_type !== 'VIDEO');
    return data;
};

/**
 * Fetches Instagram posts from the Instagram Graph API from the next page
 * @param {string} nextPage - URL to the next page of Instagram posts
 * @returns { Promise<{
* data: [{
*      id: string,
*      media_type: string,
*      caption: string,
*      media_url: string,
*      timestamp: string,
*      username: string,
*  }],
*  paging: {
*      cursors: {
*          before: string,
*          after: string
*      },
*      next: string,
*      previous: string
*  },
*  }> } Array of Instagram posts
*/
export const getInstagramPostsFromNextPage = async (nextPage) => {
    const res = await fetch(nextPage);
    let data = await res.json();
    data.data = data.data.filter(post => post.media_type !== 'VIDEO');
    return data;
};