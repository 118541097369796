import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

// Components
import BgSection from '../components/BgSection'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Card from '../components/Card'
import WorkTogether from '../components/WorkTogether'

// GSAP
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'

// Assets
import handWithBee from '../assets/images/hand-with-bee.svg'

// Functions
import getInstagramPosts, { getInstagramPostsFromNextPage } from '../functions/getInstagramPosts';
import cleanInstagramCaption from '../functions/cleanInstagramCaption';

export default function Photography() {

    // Get Instagram posts
    const [instagramPosts, setInstagramPosts] = useState([]);
    const [instagramPostsNextPage, setInstagramPostsNextPage] = useState(null);
    useEffect(() => {
        getInstagramPosts()
            .then(data => {
                setInstagramPosts(data.data)
                setInstagramPostsNextPage(data.paging.next)
            })
            .catch(err => console.error(err));
    }, []);

    // Show instagram post
    const oneImgShowcaseElement = document.querySelector('.oneImgShowcase');
    const [oneImgShowcase, setoneImgShowcase] = useState(null);
    const showImg = (post) => {
        setoneImgShowcase(post);
        if (oneImgShowcaseElement) {
            oneImgShowcaseElement.classList.add('pointer-events-auto');
            oneImgShowcaseElement.classList.remove('pointer-events-none');
        }
    }
    const closeImg = () => {
        setoneImgShowcase(null);
        if (oneImgShowcaseElement) {
            oneImgShowcaseElement.classList.remove('pointer-events-auto');
            oneImgShowcaseElement.classList.add('pointer-events-none');
        }
    }

    // Load more Instagram posts
    const loadMorePosts = () => {
        getInstagramPostsFromNextPage(instagramPostsNextPage)
            .then(data => {
                setInstagramPosts([...instagramPosts, ...data.data])
                if (data.paging.next) {
                    setInstagramPostsNextPage(data.paging.next)
                } else {
                    setInstagramPostsNextPage(null);
                }
            })
            .catch(err => console.error(err));
    }

    // GSAP animations
    // —— Fade in images ——
    const oneImageRef = useRef(null);
    useGSAP(() => {
        if (oneImgShowcase) {
            gsap.fromTo(
                oneImageRef.current,
                { opacity: 0 },  // Start with opacity 0
                { opacity: 1, duration: 0.35 }  // Fade to opacity 1 over 0.5 seconds
            );
            gsap.fromTo(
                '.oneImageRefImg',
                { scale: 0.97 },  // Start with scale 0.9
                { scale: 1, duration: 0.35, ease: 'power4.inOut' }  // Scale to 1 over 0.5 seconds
            ), '<0.35';
        }
    }, [oneImgShowcase]);

    return (
        <>
            <Helmet>
                <title>Photography | Mees Akveld</title>
                <meta name="twitter:title" content="Photography | Mees Akveld" />
				<meta property="og:title" content="Photography | Mees Akveld" />
            </Helmet>

            <div className='flex flex-col gap-0' id='scroll-container'>
                <BgSection className="pt-24 md:pt-24 flex flex-col gap-24 md:gap-40 z-10">

                    <Intro subtitle="I'm crazy about:" titlePrt1="Finding beauty" titlePrt2="In the ordinary" />

                    <Title title='Photography' titlePrt1='Photo' titlePrt2='Graphy' titleH='h1' imgPath={handWithBee} imgClassName="h-[50vw]" />

                    <Card title='Capturing beauty' description="For me, photography is an art of capturing the essence while leaving enough room for imagination and creativity. It is a method to log memorable moments and cherish them. What I find interesting is that every photo can bring back a memory, and remarkably enough, it might evoke a different memory for someone else than it does for me. It's a personal journey for each image that is created." />

                    <div className='w-full flex flex-col gap-12'>

                        <div className='imgShowcase grid grid-cols-2 max-md:items-stretch sm:grid-cols-3 md:grid-cols-5 gap-4'>
                            {instagramPosts.map((post, index) => {
                                let style = {};

                                const imgShowcase = document.querySelector('.imgShowcase');
                                if (imgShowcase && window.innerWidth > 768) {
                                    let marginTop = 0;
                                    if (index === 1) marginTop = 5.5;
                                    if (index === 2) marginTop = 11;
                                    if (index === 3) marginTop = 5.5;
                                    if (index >= 5 && index % 5 === 0) marginTop = -11;
                                    if (index >= 6 && index % 5 === 1) marginTop = -5.5;
                                    if (index >= 7 && index % 5 === 2) marginTop = 0;
                                    if (index >= 8 && index % 5 === 3) marginTop = -5.5;
                                    if (index >= 9 && index % 5 === 4) marginTop = -11;
                                    style = { marginTop: `${marginTop}vw` };
                                }

                                return (
                                    <div className='md:h-[22vw] transition-all duration-500 hover:scale-[1.02]' style={style} onClick={() => { showImg(post) }} key={post.id} >
                                        <img src={post.media_url} alt={cleanInstagramCaption(post.caption)} className={`w-full h-full rounded-[5px] object-cover pointer-events-none`} />
                                    </div>
                                )
                            })}
                        </div>

                        {instagramPostsNextPage &&
                            <button onClick={loadMorePosts} className="loading block m-auto bg-white py-1 px-12 rounded-[30px] hover:bg-blue hover:text-white transition-colors duration-300">Load more</button>
                        }

                    </div>

                    <WorkTogether />

                </BgSection>

                <div onClick={closeImg} className='oneImgShowcase absolute z-30 h-[100vh] w-full pointer-events-none' data-scroll data-scroll-sticky data-scroll-target="#scroll-container" >
                    {oneImgShowcase &&
                        <div ref={oneImageRef} className='bg-[#00000060] backdrop-blur-sm h-full relative'>
                            <div className='max-md:w-full absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex flex-col gap-4 pointer-events-none items-center'>
                                <img className='oneImageRefImg object-cover w-[80vw] max-w-[60vh] rounded-[5px]' src={oneImgShowcase.media_url} alt={cleanInstagramCaption(oneImgShowcase.caption)} />
                            </div>
                        </div>
                    }
                </div>
            </div>

        </>
    )
}
