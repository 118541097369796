export default [
    {
        name: 'JavaScript',
        description: 'Javascript is a programming language that is used to create interactive effects within web browsers. It is a versatile language that can be used to create anything from simple animations to complex web applications.',
        notes: []
    },
    {
        name: 'HTML',
        description: 'HTML is the standard markup language used to create web pages. It is the backbone of the web and is used to structure content on the internet.',
        notes: []
    },
    {
        name: 'CSS',
        description: 'CSS is the language used to style web pages. It is used to control the layout and appearance of websites, including colors, fonts, and spacing.',
        notes: []
    },
    {
        name: 'TypeScript',
        description: 'Typescript is a superset of Javascript that adds static typing to the language. It is designed to make it easier to write and maintain large-scale applications.',
        notes: [
            'Going to learn this.',
        ]
    },
    {
        name: 'PHP',
        description: 'PHP is a server-side scripting language that is used to create dynamic web pages. It is commonly used to create content management systems and e-commerce platforms.',
        notes: [
            'Going to learn this.',
            'Besides PHP, I am also going to learn Laravel.'
        ]
    },
    {
        name: 'Swift',
        description: 'Swift is a programming language that is used to create applications for iOS and macOS devices. It is designed to be fast, safe, and easy to use.',
        notes: []
    },
    {
        name: 'SwiftUI',
        description: 'SwiftUI is a framework that is used to create user interfaces for iOS and macOS applications. It is built on top of Swift and allows developers to create dynamic and interactive designs.',
        notes: []
    },
    {
        name: 'Nunjucks',
        description: 'Nunjucks is a templating language that is used to generate HTML content. It is commonly used with Node.js to create dynamic web pages.',
        notes: []
    },
    {
        name: 'YAML',
        description: 'YAML is a human-readable data serialization format that is commonly used for configuration files. It is designed to be easy to read and write.',
        notes: []
    },
    {
        name: 'SQLite3',
        description: 'SQLite3 is a lightweight relational database management system that is used to store data in web applications. It is commonly used for small-scale projects.',
        notes: []
    },
    {
        name: 'PostgreSQL',
        description: 'PostgreSQL is a powerful open-source relational database management system that is used to store data in web applications. It is commonly used for large-scale projects.',
        notes: []
    },
    {
        name: 'GraphQL',
        description: 'GraphQL is a query language for APIs that is designed to make it easier to request and receive data from a server. It is commonly used with React to create dynamic web applications.',
        notes: []
    },
    {
        name: 'GROQ',
        description: 'GROQ is a query language that is used to retrieve data from a Sanity.io database. It is designed to be simple and powerful, making it easy to work with structured data.',
        notes: []
    }
]