import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import logo from '../assets/images/logo.svg'
import menu from '../assets/icons/hamburger-menu.svg'
import xmark from '../assets/icons/xmark.svg'

export default function Header({ className = "" }) {
    const headerLinks = [
        { title: 'About', url: '/about' },
        { title: 'Projects', url: '/projects' },
        { title: 'Contact', url: '/contact' }
    ]

    const navLinks = [
        { title: 'Home', link: '/' },
        { title: 'About', link: '/about' },
        { title: 'Projects', link: '/projects' },
        { title: 'Photography', link: '/photography' },
        { title: 'Expertise', link: '/expertise' },
        { title: 'Contact', link: '/contact' }
    ]

    const contactLinks = [
        { title: 'E-mail', url: 'mailto:meesakveld@gmail.com' },
        { title: 'Github', url: 'https://www.github.com/meesakveld' },
        { title: 'LinkedIn', url: 'https://www.linkedin.com/in/meesakveld' },
        { title: 'Instagram', url: 'https://www.instagram.com/meesakveld' },
    ]

    const [navStyle, setNavStyle] = useState({ right: '-100%' })
    const [navContactStyle, setNavContactStyle] = useState({ backgroundColor: '#00000000', pointerEvents: 'none' })
    const openNav = () => {
        setNavStyle({ right: '0' })
        setNavContactStyle({ backgroundColor: '#00000050', pointerEvents: 'all' })
    }
    const closeNav = () => {
        setNavStyle({ right: '-100%' })
        setNavContactStyle({ backgroundColor: '#00000000', pointerEvents: 'none' })
    }

    return (
        <>

            <header className={`flex justify-between items-center z-10 w-full fixed left-0 top-4 py-4 px-5 md:top-6 md:px-8`}>
                <Link to="/">
                    <img style={{ width: 'clamp(1.5rem, 7vw, 2rem)' }} src={logo} alt="Logo Mees Akveld" />
                </Link>

                <div className='flex content-center gap-8 items-center'>
                    <ul className='flex gap-6'>
                        {headerLinks.map((link, index) => (
                            <li key={index} className='lined'>
                                <Link style={{ fontSize: 'clamp(0.8rem, 2vw, 1rem)' }} to={link.url}><p>{link.title}</p></Link>
                            </li>
                        ))}
                    </ul>

                    <button 
                        onClick={openNav} 
                        onKeyDown={(ev) => { if (ev.key === 'Escape') closeNav(); }}
                        className='p-[8px] w-[35px] h-[35px] flex flex-col items-center justify-center bg-gray rounded-full aspect-square'
                    >
                        <img src={menu} alt="Menu" />
                    </button>
                </div>
            </header>

            {/* ———————————— NAV ———————————— */}
            <div 
                onClick={closeNav} 
                tabIndex={0}
                className='fixed top-0 left-0 w-full h-full z-20 transition-all ease duration-[0.5s]' 
                style={navContactStyle
            }>
                <nav onClick={(ev) => {ev.stopPropagation()}} className='h-full bg-noice absolute right-0 rounded-l-3xl transition-all ease duration-[0.5s] w-90vw min-[850px]:w-50vw' style={navStyle}>

                    <button onClick={closeNav} className='p-[12px] m-4 w-[35px] h-[35px] flex flex-col items-center justify-center bg-white rounded-full aspect-square'>
                        <img src={xmark} alt="Menu" />
                    </button>

                    <div className='h-[1px] bg-gray w-full'></div>

                    <div className='pb-12 flex flex-col gap-6 overflow-scroll h-full'>

                        <ul className='mx-4 pt-4 uppercase font-passionOne text-13vw min-[850px]:text-8vw flex flex-col gap-2'>
                            {navLinks.map((link, index) => (
                                <li key={index} className='flex gap-4'>
                                    <div className='border-gray border-[1px] rounded-full aspect-square w-6 h-6 flex flex-col items-center justify-center'>
                                        <p className='font-roboto text-[12px]'>{index + 1}</p>
                                    </div>
                                    <Link onClick={closeNav} className='lined break-all' to={link.link}><p>{link.title}</p></Link>
                                </li>
                            ))}
                        </ul>

                        <ul className='mx-4 pb-4 flex gap-4'>
                            {contactLinks.map((link, index) => (
                                <li key={index} className='lined'>
                                    <a href={link.url} target='_blank'><p>{link.title}</p></a>
                                </li>
                            ))}
                        </ul>

                    </div>

                </nav>
            </div>

        </>
    )
}
