import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import '../styles/locomotive-scroll.css';

export default function Root() {
    const scrollContainerRef = useRef(null);
    const { pathname } = useLocation()

    return (
        <>
            {/* Top border */}
            <div className='fixed z-20 left-0 pointer-events-none w-screen -top-5 md:-top-12' style={{ width: '107.15%', left: '-3.56%' }}>
                <svg width="100%" viewBox="0 0 375 29" fill="rgb(var(--color-blue))" xmlns="http://www.w3.org/2000/svg" ><path fillRule="evenodd" clipRule="evenodd" d="M375 0H0V29H16V26C16 20.4772 20.4772 16 26 16H349C354.523 16 359 20.4772 359 26V29H375V0Z" fill="var(--dark)"></path></svg>
            </div>

            <Header />

            <LocomotiveScrollProvider 
                options={{ smooth: true, smartphone: { smooth: true } }} 
                watch={[]} 
                location={pathname}
                containerRef={scrollContainerRef}
                onLocationChange={scroll => scroll.scrollTo(0, { duration: 0, disableLerp: true })} 
            >
                <div data-scroll-container ref={scrollContainerRef}>
                    <main>
                        <Outlet />
                    </main>

                    <Footer />
                </div>
            </LocomotiveScrollProvider>

        </>
    )
}
