import card from '../../assets/images/projects/gentse-feesten-2023/card.png';
import banner from '../../assets/images/projects/gentse-feesten-2023/banner.png';
import showcaseWide1 from '../../assets/images/projects/gentse-feesten-2023/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/gentse-feesten-2023/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/gentse-feesten-2023/showcase-wide-3.png';
import devider from '../../assets/images/projects/gentse-feesten-2023/devider.png';
import mobile1 from '../../assets/images/projects/gentse-feesten-2023/mobile-1.png';
import mobile2 from '../../assets/images/projects/gentse-feesten-2023/mobile-2.png';
import mobile3 from '../../assets/images/projects/gentse-feesten-2023/mobile-3.png';

export default {
    title: "Gentse Feesten 2023",
    slug: 'gentse-feesten-2023',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/gentse-feesten-2023",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: "https://meesakveld.github.io/gentse-feesten-2023/",
    },
    description: [
        `This project is a recreation of the original Gentse Feesten website, built with HTML, CSS, and JavaScript. The website is responsive and accessible on all devices.`,
        `Data is fetched from an API and displayed dynamically on the site. This project was completed as an assignment for the course @Work1 at Artevelde University of Applied Sciences.`,
        `The goal was to recreate the Gentse Feesten 2023 website, learn how to fetch data from an API, and showcase my knowledge of HTML, CSS, and JavaScript after eight weeks of learning.`
    ],
    developmentNotes: [
        { title: "Component-Based Approach", text: "Adopted a component-based approach in JavaScript for the first time, allowing for reusable and easily manageable code components." },
        { title: "Responsive Design", text: "Ensured the website is fully responsive and functional across all devices, providing a consistent user experience." },
        { title: "API Integration", text: "Fetched data from an API and dynamically displayed it on the website, enhancing the interactivity and relevance of the content." },
        { title: "CSS Nesting", text: "Utilized CSS nesting for the first time to maintain a more organized and structured CSS file, improving the project's overall maintainability." }
    ],
    mostProudOf: [
        `I’m most proud of adopting a component-based approach in JavaScript for the first time. This method allowed me to create and modify components efficiently, rather than duplicating code across the project.`,
        `Additionally, exploring CSS nesting was a rewarding experience. It significantly helped in organizing the CSS file and bringing structure to the project, making the codebase cleaner and more manageable.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}