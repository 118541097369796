import card from '../../assets/images/projects/recipe-book/card.png';
import banner from '../../assets/images/projects/recipe-book/banner.png';
import showcaseWide1 from '../../assets/images/projects/recipe-book/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/recipe-book/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/recipe-book/showcase-wide-3.png';
import devider from '../../assets/images/projects/recipe-book/devider.png';
import mobile1 from '../../assets/images/projects/recipe-book/mobile-1.png';
import mobile2 from '../../assets/images/projects/recipe-book/mobile-2.png';
import mobile3 from '../../assets/images/projects/recipe-book/mobile-3.png';

export default {
    title: "Recipe Book",
    slug: 'recipe-book',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/recipe-book",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `This project is a recipe book web application that allows users to view, add, edit, and delete recipes.`,
        `The application consists of a front-end built with basic HTML, CSS, and JavaScript, and a back-end using Node.js and Express.js. Data is stored in a JSON file.`,
        `This project was my first full-stack web development experience, where I gained familiarity with back-end development aspects such as routing, handling requests, and managing data.`
    ],
    developmentNotes: [
        { title: "Front-End", text: "Built with HTML, CSS, and JavaScript to create a user-friendly interface for managing recipes." },
        { title: "Back-End", text: "Developed using Node.js and Express.js to handle routing, requests, and data management." },
        { title: "Data Storage", text: "Utilized a JSON file for storing recipe data, allowing for easy retrieval and updates." },
        { title: "Full-Stack Experience", text: "This project marked my first foray into full-stack development, enhancing my understanding of both front-end and back-end technologies." }
    ],
    mostProudOf: [
        `One feature I’m particularly proud of is the ability to switch between dark mode and light mode. I thoroughly enjoyed researching and implementing this functionality, and it added a personal touch to the project.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}