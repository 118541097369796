import React from 'react'
import { Link } from 'react-router-dom'

export default function Button({ title, bgColor="blue", url, target="_self" }) {
    const Tag = url ? Link : 'div'

    const style = {
        backgroundColor: bgColor === "white" ? 'rgb(var(--color-blue))': "rgb(var(--color-white))",
        color: bgColor === "white" ? 'rgb(var(--color-blue))': "rgb(var(--color-white))",
        width: 'clamp(15rem, 23.5vw, 23.5vw)'
    }

    return (
        <Tag title={title} to={url} target={target} style={style} className={`text-center uppercase p-3 rounded-[900px] block text-sm relative overflow-hidden ${url ? 'btn-hover' : ""}`}>
            <p type={bgColor} className={`relative z-[1] transition-all duration-500`}>{title}</p>

            <div className='arrow'>
                <span className='arrow-img' style={{backgroundColor: bgColor === "blue" ? 'rgb(var(--color-white))': "rgb(var(--color-blue))"}}></span>
            </div>

            <div 
                className='background absolute w-full h-full top-0 left-0 transition-all duration-500'
                style={{backgroundColor: bgColor === "white" ? 'rgb(var(--color-white))': "rgb(var(--color-blue))"}}
            ></div>

        </Tag>
    )
}
