import card from '../../assets/images/projects/studio-clean/card.png';
import banner from '../../assets/images/projects/studio-clean/banner.png';
import showcaseWide1 from '../../assets/images/projects/studio-clean/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/studio-clean/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/studio-clean/showcase-wide-3.png';
import devider from '../../assets/images/projects/studio-clean/devider.png';
import mobile1 from '../../assets/images/projects/studio-clean/mobile-1.png';
import mobile2 from '../../assets/images/projects/studio-clean/mobile-2.png';
import mobile3 from '../../assets/images/projects/studio-clean/mobile-3.png';

export default {
    title: "Studio Clean",
    slug: 'studio-clean',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: "https://github.com/meesakveld/studio-clean",
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `This project is an 11ty static site generator project developed for the User Interface Prototyping course at Artevelde University of Applied Sciences.`,
        `The objective was to build a static site generator that produces a website for a fictitious web design studio named Studio Clean.`,
        `The website is template-based, utilizing Nunjucks and Markdown, and built with 11ty.`
    ],
    developmentNotes: [
        { title: "Technology Stack", text: "The project is built using the 11ty static site generator, Nunjucks for templating, and Markdown for content creation." },
        { title: "11ty", text: "Used 11ty to create a flexible, static site generator that easily compiles templates and markdown into a complete website." },
        { title: "Nunjucks", text: "Implemented Nunjucks as the templating engine, enabling dynamic and reusable components across the site." },
        { title: "Markdown", text: "Markdown was utilized for writing the content, allowing for easy formatting and integration with 11ty." }
    ],
    mostProudOf: [
        `I'm really proud of how I managed to create a fully functional static site generator using 11ty. It was my first time working with a static site generator, and seeing the final product come together was incredibly satisfying.`,
        `I'm also proud of how I integrated Nunjucks templates and Markdown content, which made the site both flexible and easy to maintain. This project really pushed me to think critically about how to structure and organize content effectively.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}